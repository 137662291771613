import * as React from "react";
import { Parallax } from "react-parallax";
import { Card, Col, Container, Row } from "react-bootstrap";

import image from "../../images/meeting.jpg";
import stretchinSvg from "../../images/stretching.svg";
import chooseSvg from "../../images/choose.svg";
import Layout from "../../components/layout";
import Section from "../../components/section";
import Typography from "../../components/typography";
import ScheduleForm from "../../components/scheduleForm";
import { StaticImage } from "gatsby-plugin-image";

const LoanOfficers = () => {
	return (
		<Layout nextPage={{ link: "/banks", text: "Banks & Credit Unions" }}>
			<Section size="md" color="light" staticHeight>
				<Parallax
					className="d-flex justify-content-center align-items-center h-100"
					bgImage={image}
					strength={750}>
					<Container className="text-center h-100">
						<div>
							<Typography type="head">Credit Union Loan</Typography>
						</div>
					</Container>
				</Parallax>
			</Section>
			<Section size="sm" color="dark" bg="light">
				<Container className=" py-5">
				<div className="hide-on-mobile">
					<Typography className="mb-3" type="title">
						What is a Credit Union Loan?
					</Typography>
					</div>
					<div className="hide-on-desktop text-center">
					<Typography className="mb-3" type="title">
						What is a Credit Union Loan?
					</Typography>
					</div>
					<Typography type="paragraph">
                    While nearly identical to a bank in what it can provide, a credit union is a non-profit financial center run by its own
                    members. Similarly to a bank loan, a credit union loan offers funding to be spent on anything, and must be paid
                    back over a specific period of time with interest. In contrast, you must become a member of a credit union before
                    you can receive a loan from that institution. If you meet the membership requirements of that credit union, you also
                    must pay a one-time membership fee and a deposit of up to $25.
					</Typography>
				</Container>
			</Section>
			<Section color="light" bg="dark">
				<Container className="text-center py-5">
					<Typography type="title">Loan Details</Typography>
					<Row className="d-flex justify-content-center mx-auto">
						<Card
							style={{ maxWidth: "200px", minHeight: "125px" }}
							className="d-flex justify-content-center align-items-center mx-3">
							<Typography type="paragraph">Max. Loan Amount</Typography>
							<Typography type="subject">$250 to $50k</Typography>
						</Card>
						<Card
							style={{ maxWidth: "200px", minHeight: "125px" }}
							className="d-flex justify-content-center align-items-center mx-3">
							<Typography type="paragraph">Loan Term</Typography>
							<Typography type="subject">3 - 25 years</Typography>
						</Card>
						<Card
							style={{ maxWidth: "200px", minHeight: "125px" }}
							className="d-flex justify-content-center align-items-center mx-3">
							<Typography type="paragraph">Interest Rates</Typography>
							<Typography type="subject">3% - 12%</Typography>
						</Card>
						<Card
							style={{ maxWidth: "200px", minHeight: "125px" }}
							className="d-flex justify-content-center align-items-center mx-3">
							<Typography type="paragraph">Speed of Funding</Typography>
							<Typography type="subject">1 - 2 months</Typography>
						</Card>
					</Row>
					<Typography className="mt-5" type="title">
						Loan Requirements
					</Typography>
					<Row className="d-flex justify-content-center mx-auto">
						<Typography type="paragraph">
                        In contrast to other loan types, a more holistic approach is taken when evaluating whether a
                        business qualifies for a credit union loan. This is because you must first become a member of a
                        specific credit union before you apply for a loan. While annual revenue, credit score, and time in
                        business among other things are important, no one factor is disqualifying.
                        </Typography>
					</Row>
				</Container>
			</Section>
			<Section size="md">
				<Container
					fluid
					className="d-flex flex-nowrap h-100 align-items-center h-100 p-5">
					<div className="nom d-inline-block me-5">
						<img
							className="h-100"
							style={{ maxHeight: "275px" }}
							src={stretchinSvg}
							alt="Two People Agreeing on Document Terms"></img>
					</div>
					<div className="d-inline-block flex-full">
						<Row className="w-100 h-100">
							<div className="flex-full">
								<Typography type="title">Pros</Typography>
								<ul>
									<li>Low interest rates</li>
									<li>Low fees</li>
									<li>Easier to qualify than banks</li>
									<li>Flexible loan terms</li>
								</ul>
							</div>
							<div className="flex-full mr-5">
								<Typography type="title">Cons</Typography>
								<ul>
									<li>Slower speed of funding</li>
									<li>Hard to find the right credit union</li>
									<li>Low-tech experience</li>
								</ul>
							</div>
						</Row>
					</div>
				</Container>
			</Section>
			<Section size="lg" bg="dark" color="light">
				<Container className="py-5">
					<div className="hide-on-mobile">
					<Typography type="title">How To Apply for a Credit Union Loan</Typography>
					</div>
					<div className="hide-on-desktop text-center">
					<Typography type="title">How To Apply for a Credit Union Loan</Typography>
					</div>

					<Row className="mt-4">
						<div className="d-flex align-items-center flex-full tl-min py-4">
							<Typography type="subject">
								<span className="numList me-3">1</span>
							</Typography>
							<div>
								<Typography type="subject">Find A Credit Union</Typography>
								<Typography type="paragraph">
									Search for the right credit union to join.
								</Typography>
							</div>
						</div>
						<div className="d-flex align-items-center flex-full tl-min py-4">
							<Typography type="subject">
								<span className="numList me-3">2</span>
							</Typography>
							<div>
								<Typography type="subject">Become a Member</Typography>
								<Typography type="paragraph">
									Join the credit union so you can have acess to loans.
								</Typography>
							</div>
						</div>
						<div className="d-flex align-items-center flex-full tl-min py-4">
							<Typography type="subject">
								<span className="numList me-3">3</span>
							</Typography>
							<div>
								<Typography type="subject">Apply and Recieve</Typography>
								<Typography type="paragraph">
									Apply for the loan you need. After approval, funds will be directly deposited
                                    into your bank account and available for immediate use. 
								</Typography>
							</div>
						</div>
					</Row>

					<div className="hide-on-mobile mt-5">
						<Typography type="title">Needed Documentation</Typography>
						<ul className="d-flex flex-wrap">
							<li className="m-0 me-5 mb-3">Business information</li>
							<li className="m-0 me-5 mb-3">Business plan</li>
							<li className="m-0 me-5 mb-3">Recent bank statements</li>
							<li className="m-0 me-5 mb-3">Other financial documents</li>
							<li className="m-0 me-5 mb-3">Tax returns</li>
						</ul>
					</div>
					<div className="hide-on-desktop mt-5">
						<Typography className="text-center" type="title">Needed Documentation</Typography>
						<ul className="d-flex flex-wrap">
							<li className="m-0 me-5 mb-3">Business information</li>
							<li className="m-0 me-5 mb-3">Business plan</li>
							<li className="m-0 me-5 mb-3">Recent bank statements</li>
							<li className="m-0 me-5 mb-3">Other financial documents</li>
							<li className="m-0 me-5 mb-3">Tax returns</li>
						</ul>
					</div>
				</Container>
			</Section>
			<Section size="md">
				<Container className="d-flex flex-nowrap h-100 align-items-center h-100 py-5">
					<div className="nom d-inline-block flex-full me-5">
						<img
							className="h-100"
							style={{ maxHeight: "400px" }}
							src={chooseSvg}
							alt="Two People Agreeing on Document Terms"></img>
					</div>
					<div className="d-inline-block nom">
						<Typography type="title">Best Use of a Credit Union Loan</Typography>
						<div className="d-flex flex-wrap mx-auto">
							<ul className="flex-full me-3">
								<li>Refinancing</li>
								<li>Purchasing large equipment</li>
								<li>Maintaining cash flow</li>
							</ul>
							<ul className="flex-full">
								<li>Debt consolidation</li>
								<li>Investment opportunities</li>
							</ul>
						</div>
					</div>
					<div className="d-inline-block nomm">
						<Typography className="text-center" type="title">Best Use of a Credit Union Loan</Typography>
						<div className="d-flex flex-wrap mx-auto">
							<ul className="flex-full me-3">
								<li>Refinancing</li>
								<li>Purchasing large equipment</li>
								<li>Maintaining cash flow</li>
								<li>Debt consolidation</li>
								<li>Investment opportunities</li>
							</ul>
						</div>
					</div>
				</Container>
			</Section>
		</Layout>
	);
};

export default LoanOfficers;
